import { useQuery, UseQueryOptions } from 'react-query';
import { ApiError } from '../../utils/api';
import { CollectionCategory } from '../../sci-ui-components/types/collectionCategory';
import { GetCollectionCategoriesParams, getCollectionCategories } from '../../services/sciApi/collections/categories';
import useAuth from '../auth/useAuth';

export const keyPrefix = 'collection-categories';
type Key = [typeof keyPrefix, GetCollectionCategoriesParams];

export default function useCollectionCategories(
  params: GetCollectionCategoriesParams,
  options: UseQueryOptions<CollectionCategory[], ApiError, CollectionCategory[], Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix, params], ({ signal }) => getCollectionCategories(params, signal), {
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
    enabled: isLoggedIn && (options?.enabled ?? true),
  });

  return queryResult;
}

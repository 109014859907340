import qs from 'qs';
import { ApiCollectionCategory } from '../types';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { CollectibleType, isSportsCardCollectibleType } from '../../../sci-ui-components/types/collectibleType';
import collectionCategoryFromApi from './transformers/collectionCategoryFromApi';
export interface GetCollectionCategoriesParams {
  collectibleType: CollectibleType;
}

export async function getCollectionCategories(
  { collectibleType }: GetCollectionCategoriesParams,
  signal: AbortSignal | undefined
) {
  const apiResult = await authenticatedFetchFromSciApi<ApiCollectionCategory[]>(
    `/collectionCategory${qs.stringify(
      {
        onlyCards: isSportsCardCollectibleType(collectibleType),
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return apiResult.map((apiItem) =>
    collectionCategoryFromApi(apiItem, {
      collectibleType,
    })
  );
}

export interface AddCollectionCategoryParams {
  name: string;
  collectibleType: CollectibleType;
}

export async function addCollectionCategory({ name, collectibleType }: AddCollectionCategoryParams) {
  const apiItem = await authenticatedFetchFromSciApi<ApiCollectionCategory>('/collectionCategory', {
    method: 'POST',
    body: JSON.stringify({
      name,
      onlyCards: isSportsCardCollectibleType(collectibleType),
    }),
  });

  return collectionCategoryFromApi(apiItem, {
    collectibleType,
  });
}

export interface UpdateCollectionCategoryParams {
  categoryId: number;
  name: string;
}

export async function updateCollectionCategory({ name, categoryId }: UpdateCollectionCategoryParams) {
  await authenticatedFetchFromSciApi<[number]>(`/collectionCategory/${categoryId}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
    }),
  });
}

export interface DeleteCollectionCategoryParams {
  categoryId: number;
}

export async function deleteCollectionCategory({ categoryId }: DeleteCollectionCategoryParams) {
  await authenticatedFetchFromSciApi<{ meessage: string }>(`/collectionCategory/${categoryId}`, {
    method: 'DELETE',
  });
}

import { useMutation, useQueryClient } from 'react-query';
import useAnalytics from '../analytics/useAnalytics';
import {
  addCollectionCategory,
  AddCollectionCategoryParams,
  deleteCollectionCategory,
  DeleteCollectionCategoryParams,
  updateCollectionCategory,
  UpdateCollectionCategoryParams,
} from '../../services/sciApi/collections/categories';
import { keyPrefix as categoriesKeyPrefix } from './useCollectionCategories';
import { useListCollectionItemsKeyPrefix } from './useListCollectionItems';
import { useAuthenticatedMMApiMutation, MmApiInput, MmApiOutput, mmApiClient } from 'services/mmApiX/index';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { showError, showSuccess } from 'services/toaster';

export type CreateCollectionCategoriesInput = Exclude<
  MmApiInput['private']['collection']['createCollectionCategories'],
  void
>;
export type CreateCollectionCategoriesOutput = MmApiOutput['private']['collection']['createCollectionCategories'];

export default function useCollectionCategoryMutations() {
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const create = useMutation({
    mutationFn: (params: AddCollectionCategoryParams) => addCollectionCategory(params),
    mutationKey: ['add-collection-category'],
    onSuccess: (_, params) => {
      // TODO: narrow down refetch to specific collectibleType
      queryClient.refetchQueries([categoriesKeyPrefix]);
      showSuccess({
        description: `Successfully added "${params.name}" collection category`,
      });
      trackEvent({
        eventName: 'COLLECTION_CREATE_COLLECTION_CATEGORY',
      });
    },
    onError: () => {
      showError({
        description: 'This category already exists.',
      });
    },
  });
  const createMultiple = useAuthenticatedMMApiMutation(
    (params: CreateCollectionCategoriesInput) =>
      mmApiClient.private.collection.createCollectionCategories.mutate(params),
    {
      mutationKey: ['add-multiple-collection-category'],
      onSuccess: (_, params) => {
        queryClient.refetchQueries([categoriesKeyPrefix]);
        showSuccess({
          description: `Successfully added "${params.names.join(', ')}" to collection categories`,
        });
        trackEvent({
          eventName:
            params.names.length > 1
              ? 'COLLECTION_CREATE_MULTIPLE_COLLECTION_CATEGORY'
              : 'COLLECTION_CREATE_COLLECTION_CATEGORY',
        });
      },
      onError: () => {
        showError({
          description: 'This category already exists.',
        });
      },
    }
  );
  const update = useMutation({
    mutationFn: (params: UpdateCollectionCategoryParams) => updateCollectionCategory(params),
    mutationKey: ['update-collection-category'],
    onSuccess: (_, params) => {
      queryClient.refetchQueries([categoriesKeyPrefix]);
      showSuccess({
        description: `Successfully renamed collection category to "${params.name}"`,
      });
      trackEvent({
        eventName: 'COLLECTION_UPDATE_COLLECTION_CATEGORY',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to rename collection category. Please try again.',
      });
    },
  });
  const remove = useMutation({
    mutationFn: (params: DeleteCollectionCategoryParams) => deleteCollectionCategory(params),
    mutationKey: ['delete-collection-category'],
    onSuccess: () => {
      queryClient.refetchQueries([categoriesKeyPrefix]);
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      showSuccess({
        description: 'Successfully deleted collection category',
      });
      trackEvent({
        eventName: 'COLLECTION_DELETE_COLLECTION_CATEGORY',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete collection category. Please try again.',
      });
    },
  });
  const removeWithConfirmation = async (params: DeleteCollectionCategoryParams) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete collection category',
      description: 'Are you sure you want to delete this collection category?',
    });
    if (confirmed) {
      await remove.mutateAsync(params);
    } else {
      trackEvent({
        eventName: 'COLLECTION_DELETE_COLLECTION_CATEGORY_CANCELLED',
      });
    }
    return confirmed;
  };

  return {
    create,
    createMultiple,
    removeWithConfirmation,
    update,
  };
}
